<section class="services-area pt-100 pb-70 bg-f1f8fb">
    <div class="container">
        <div class="section-title" *ngFor="let sectionTitleContent of sectionTitle;">
            <h2>{{sectionTitleContent.title}}</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let solutionsBoxContent of singleSolutionsBox;">
                <div class="single-solutions-box">
                    <div class="icon">
                        <img [src]="solutionsBoxContent.icon" alt="image">
                    </div>
                    <h3><a routerLink="/{{solutionsBoxContent.link}}">{{solutionsBoxContent.title}}</a></h3>
                    <p>{{solutionsBoxContent.paragraphText}}</p>
                    <a routerLink="/{{solutionsBoxContent.link}}" class="view-details-btn">{{solutionsBoxContent.linkText}}</a>
                </div>
            </div>
        </div>
    </div>
</section>