<app-homefour-banner></app-homefour-banner>

<app-homefour-featured-services></app-homefour-featured-services>

<app-homefour-why-choose-us></app-homefour-why-choose-us>

<app-homefour-what-we-do></app-homefour-what-we-do>

<app-homefour-funfacts></app-homefour-funfacts>

<app-homefour-services></app-homefour-services>

<app-homefour-testimonials></app-homefour-testimonials>

<app-homefour-industries-serve></app-homefour-industries-serve>

<app-hometwo-scientist></app-hometwo-scientist>

<app-homeone-case-studies></app-homeone-case-studies>

<app-homefour-blog></app-homefour-blog>

<app-project-start></app-project-start>