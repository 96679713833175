<section class="courses-area bg-fafafb pt-100 pb-70">
    <div class="container">
        <div class="section-title" *ngFor="let sectionTitleContent of sectionTitle;">
            <h2>{{sectionTitleContent.title}}</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let coursesContent of singleCoursesBox;">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/{{coursesContent.link}}" class="d-block image">
                            <img [src]="coursesContent.courseImg" alt="image">
                        </a>
                        <div class="price shadow {{coursesContent.coursePriceClass}}">{{coursesContent.coursePrice}}</div>
                    </div>

                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img [src]="coursesContent.authorImg" class="rounded-circle" alt="image">
                            <span>{{coursesContent.authorName}}</span>
                        </div>

                        <h3><a routerLink="/{{coursesContent.link}}">{{coursesContent.title}}</a></h3>
                        <p>{{coursesContent.paragraphText}}</p>

                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li>
                                <i class='flaticon-agendas'></i> {{coursesContent.courseDuration}}
                            </li>
                            <li>
                                <i class='flaticon-team'></i> {{coursesContent.studentApply}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>