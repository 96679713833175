<section class="page-title-area">
    <div class="container">
        <div class="page-title-content" *ngFor="let pageTitle of pageTitleArea;">
            <h2>{{pageTitle.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{pageTitle.title}}</li>
            </ul>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
</section>

<section class="courses-area ptb-100 bg-fafafb">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let coursesContent of singleCoursesBox;">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/{{coursesContent.link}}" class="d-block image">
                            <img [src]="coursesContent.courseImg" alt="image">
                        </a>
                        <div class="price shadow {{coursesContent.coursePriceClass}}">{{coursesContent.coursePrice}}</div>
                    </div>

                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img [src]="coursesContent.authorImg" class="rounded-circle" alt="image">
                            <span>{{coursesContent.authorName}}</span>
                        </div>

                        <h3><a routerLink="/{{coursesContent.link}}">{{coursesContent.title}}</a></h3>
                        <p>{{coursesContent.paragraphText}}</p>

                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li>
                                <i class='flaticon-agendas'></i> {{coursesContent.courseDuration}}
                            </li>
                            <li>
                                <i class='flaticon-team'></i> {{coursesContent.studentApply}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center">
                    <a routerLink="/courses" class="prev page-numbers"><i class='bx bx-chevrons-left'></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/courses" class="page-numbers">2</a>
                    <a routerLink="/courses" class="page-numbers">3</a>
                    <a routerLink="/courses" class="page-numbers">4</a>
                    <a routerLink="/courses" class="next page-numbers"><i class='bx bx-chevrons-right'></i></a>
                </div>
            </div>
        </div>
    </div>
</section>